
<template>
  <div class="wchat-box d-flex align-items-center min-vh-100">
    <CContainer fluid>
      <CRow class="justify-content-center" style="margin-top:1rem">
        <CCol md="6">
          <CCardGroup>
            <CCard class="mx-4 mb-0">
              <CCardBody class="p-4">
                <div class="wma-box">
                <div class="return-icon">
                    <svg @click="toLogin" style="margin-bottom:.2rem;cursor:pointer" height="35" width="35" xmlns="http://www.w3.org/2000/svg" class="icon icon-xxl" viewBox="0 0 512 512" role="img">undefined<path fill="var(--ci-primary-color, currentColor)" d="M256,16.042c-132.548,0-240,107.451-240,240s107.452,240,240,240,240-107.452,240-240S388.548,16.042,256,16.042ZM403.078,403.12A207.253,207.253,0,1,1,447.667,337,207.364,207.364,0,0,1,403.078,403.12Z" class="ci-primary"></path><polygon fill="var(--ci-primary-color, currentColor)" points="272.614 164.987 249.986 142.36 136.305 256.041 249.986 369.722 272.614 347.095 197.56 272.041 385 272.041 385 240.041 197.56 240.041 272.614 164.987" class="ci-primary"></polygon></svg>
                    <h3 style="margin-left: .88rem">{{ $t('WechatBind.WechatLogin') }}</h3>
              </div>
                  <div class="tip">{{ $t('WechatBind.MaTip') }}</div>
                  <div class="ma-box">
                    <!-- <vueQr
                      :text="erSrc"
                      :size="size"
                      :logoScale="logoScale"
                    ></vueQr> -->
                    <img style="width:388px;height:388px" :src="`https://mp.weixin.qq.com/cgi-bin/showqrcode?ticket=${erCode}`" alt="">
                  </div>
                  <!-- <div class="success-text">{{ message }}</div> -->
                  <div v-if="suc">
                    <el-alert
                      :title="message"
                      type="success"
                    >
                    </el-alert>
                  </div>
                  <!-- <div class="success-text" v-if="code == 200">{{ message }}</div> -->
                  <!-- <div class="success-text" v-else-if="code == 401">{{ message }}</div> -->
                </div>
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
import vueQr from "vue-qr";
import { getWxQrCode, wxScanLogin } from "../../api/http";
export default {
  components: { vueQr },
  data() {
    return {
      erCode: "",
      logoScale: 0.2,
      size: 388,
      qr_id: "",
      code: 403,
      message: "",
      timer: null,
      suc: false
    };
  },
  watch: {
      code: {
          handler(val) {
              if(val == 401) {
                  this.message = this.$t('WechatBind.Code401')
                  setTimeout(() => {
                      this.$router.push({
                          path: "/pages/wechat-bind",
                          query: {qr_id: this.qr_id}
                      })
                  }, 2000)
              }else if(val == 200) {
                  this.suc = true
                  clearInterval(this.timer)
                  this.timer = null
                  this.message = this.$t('WechatBind.Code200')
              }else {
                  this.message = ""
              }
          }
      }
  },
  computed: {
    // https://mp.weixin.qq.com/cgi-bin/showqrcode?ticket=gQHH7zwAAAAAAAAAAS5odHRwOi8vd2VpeGluLnFxLmNvbS9xLzAyTHJZekJwQXFmVUQxMGhCb056MUoAAgQJHhhjAwQIBwAA
    // https://mp.weixin.qq.com/cgi-bin/showqrcode?
    erSrc() {
      return (
        "https://mp.weixin.qq.com/cgi-bin/showqrcode?ticket="+this.erCode
      );
    },
    getQrId() {
        return this.qr_id
    }
  },
  created() {
    this.getWcMa();
    this.setTimeWxLogin()
  },
  destroyed() {
      clearInterval(this.timer)
      this.timer = null
  },
  methods: {
    toLogin() {
        this.$router.push("/pages/login")
    },
    // 獲取微信登陸二維碼
    getWcMa() {
      let data = {
        type: "get_wx_qr_code",
        uid: localStorage.getItem("uid"),
      };
      getWxQrCode(data).then((res) => {
        console.log("res==>", res);
        if (res.code == 200) {
          this.erCode = res.data.ticket;
          this.qr_id = res.data.id
        } else {
          this.$message.error(res.message);
        }
      });
    },
    setTimeWxLogin() {
        clearInterval(this.timer)
        this.timer = setInterval(() => {
            this.reqWxLogin()
        }, 1000)
    },
    // 請求微信登陸
    reqWxLogin() {
      let data = {
        type: "wx_scan_login",
        qr_id: this.getQrId,
        user_name: "",
        password: ""
      };
      wxScanLogin(data).then((res) => {
        if (res.code == 200) {
            this.code = res.code
            sessionStorage.setItem("isLogin", 1);
            this.$store.commit("setUserInfo", res.data[0]);

            const uid = res.data.map((item) => item.id);
            const user_name = res.data.map((item) => item.user_name);
            const is_agent = res.data.map((item) => item.is_agent)
            window.localStorage.setItem("uid", uid);
            window.localStorage.setItem("user_name", user_name);
            window.localStorage.setItem("is_agent", is_agent)

            setTimeout(() => {
                this.$router.push({ path: "/dashboard" });
            }, 2000)
        } else if(res.code == 401) {
            this.code = res.code
        } 
      })
    //   let formData = new FormData();
    //   for (let k in data) {
    //     formData.append(k, data[k]);
    //   }
    //  this.$axios({
    //     method: "POST",
    //     url: "/request/user.php",
    //     data: formData,
    //   }).then((code) => {
    //       console.log('code==>', code)
    //     if (code.data.code == 200) {
    //         this.code = code.data.code
    //         sessionStorage.setItem("isLogin", 1);
    //         this.$store.commit("setUserInfo", code.data.data[0]);

    //         const uid = code.data.data.map((item) => item.id);
    //         const user_name = code.data.data.map((item) => item.user_name);
    //         const is_agent = code.data.data.map((item) => item.is_agent)
    //         window.localStorage.setItem("uid", uid);
    //         window.localStorage.setItem("user_name", user_name);
    //         window.localStorage.setItem("is_agent", is_agent)

    //         setTimeout(() => {
    //             this.$router.push({ path: "/dashboard" });
    //         }, 2000)
    //     } else if(code.data.code == 401) {
    //         this.code = code.data.code
    //     } 
    //     else {
    //     //   this.$message.error(code.data.message);
    //     }
    //   })
    },
  },
};
</script>
<style scoped>
.wma-box {
  padding: 20px;
}
.tip {
  color: #3b4c64;
  font-size: 16px;
}
.success-text {
  color: #2cdd71;
  font-weight: bold;
  font-size: 22px;
  text-align: center;
}
.ma-box{
    text-align: center;
}
.return-icon{
    display: flex;
    align-items: center;
}
</style>